import React from "react";
import BuyTicket from '../../assets/images/aipaye-telugu.png';
const BuyTickets = () => {
   return (
     <div className="flex justify-center items-center h-[80vh]">
       <img src={BuyTicket} alt="image"/>
     </div>
   )
 }

export default BuyTickets;